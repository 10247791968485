import { DestroyRef, Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjectManagementTask } from 'app/api';
import { Project, OpenProjectService, WorkPackageService } from 'app/api-project';

import { WorkpackageInner } from 'app/api/model/workpackageInner';
import { AlertService } from 'app/core/alert/alert.service';
import { Observable, Subject, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProjectPageService {
    _destroy: Subject<void> = new Subject();
    _selectedProject = signal<Project>(null);
    _editableForm = signal<boolean>(false);

    editedProcessesSignal: WritableSignal<Project[]> = signal([]);

    _projects = signal<Array<Project>>([]);
    public set projects(projects: Array<Project>) {
        this._projects.set(projects);
    }

    public get projects() {
        return this._projects();
    }

    public set selectedProject(project: Project) {
        this._selectedProject.set(project);
    }

    public get selectedProject() {
        return this._selectedProject();
    }

    // complete tasks state
    private _model: WritableSignal<WorkpackageInner[]> = signal([]);
    public get model(): Signal<WorkpackageInner[]> {
        return this._model;
    }
    public set model(tasks: WorkpackageInner[]) {
        this._model.set(tasks);
    }

    // tasks that are currently visible on the screen
    private _shownTasks: WritableSignal<ProjectManagementTask[]> = signal([]);
    public get shownTasks(): Signal<ProjectManagementTask[]> {
        return this._shownTasks.asReadonly();
    }
    public set shownTasks(tasks: ProjectManagementTask[]) {
        this._shownTasks.set(tasks);
    }

    constructor(
        private _apiService: OpenProjectService,
        private _alertService: AlertService,
        private _serviceWorkPackage: WorkPackageService,
        private _destroyRef: DestroyRef
    ) {
        this.getProjects();
    }

    // API requests

    // GET API
    public getTasks(): void {
        this._apiService.openprojectApiWorkPackageGet().subscribe(data => {
            // this.model = data;
        });
    }

    public getProjects() {
        this._apiService.openprojectApiProjectGet().subscribe(data => {
            this._projects.set(data);
        });
    }

    public getProjectsById(id: number) {
        this._serviceWorkPackage.openprojectApiWorkPackageIdGet(id).subscribe(data => {
            this._model.set(data);
        });
    }

    deleteProject(id: number) {
        this._apiService.openprojectApiProjectIdDelete(id).subscribe();
    }

    // PUT API
    public updateTask(task: WorkpackageInner): void {
        let tasks = this.model();
        let originalTaskIndex = tasks.findIndex(t => t.id === task.id);
        let originalTask = tasks[originalTaskIndex];
        let newTask = {
            ...originalTask,
            ...task,
        };
        // sometimes the 3rd party gantt chart library convert the dependencies from string to array
        // this creates problem in creating tree to find parent id
        // hence converting back to string
        // if (Array.isArray(newTask.relations)) {
        //     newTask.relations = newTask.relations.join('');
        // }
        this._serviceWorkPackage.openprojectApiWorkPackageIdPut(newTask.id, [newTask]).subscribe(
            data => {
                this._model.update(tasks => {
                    tasks[originalTaskIndex] = newTask;
                    return [...tasks];
                });
                this._alertService.successAlert(
                    'taskUpdateSuccessMessage',
                    'updated',
                    'projectManagement'
                );
            },
            error => {
                this._model.update(tasks => {
                    tasks[originalTaskIndex] = originalTask;
                    return [...tasks];
                });
            }
        );
    }

    updateProject(project: Project): Observable<Project> {
        return this._apiService.openprojectApiProjectIdPut(project.id, project);
    }

    createNewProject(data: Partial<Project>): Observable<any> {
        return new Observable(observer => {
            this._apiService
                .createProject(data)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe({
                    next: data => {
                        observer.next(data);
                        this.getProjects();
                        this._alertService.successAlert(
                            'project',
                            'newProjectCreateSuccessfulMessage',
                            'created'
                        );
                    },
                    error: err => observer.error(err),
                });
        });
    }
}

export const mockTask = {
    id: 10,
    typeId: 3,
    projectId: 1,
    subject: 'Follow-up tasks',
    description: '',
    dueDate: '2024-05-29T22:00:00.000+00:00',
    categoryId: null,
    statusId: 5,
    assignedToId: 4,
    priorityId: 8,
    versionId: null,
    authorId: 4,
    lockVersion: 0,
    doneRatio: null,
    estimatedHours: null,
    createdAt: '2024-04-29T08:32:59.271+00:00',
    updatedAt: '2024-04-29T08:32:59.722+00:00',
    startDate: '2024-05-19T22:00:00.000+00:00',
    responsibleId: null,
    budgetId: null,
    position: 1,
    storyPoints: null,
    remainingHours: null,
    derivedEstimatedHours: null,
    scheduleManually: false,
    parentId: null,
    duration: 9,
    ignoreNonWorkingDays: false,
    derivedRemainingHours: null,
    derivedDoneRatio: null,
    children: [
        {
            id: 11,
            typeId: 1,
            projectId: 1,
            subject: 'Upload presentations to website',
            description: '',
            dueDate: '2024-05-28T22:00:00.000+00:00',
            categoryId: null,
            statusId: 1,
            assignedToId: 4,
            priorityId: 8,
            versionId: null,
            authorId: 4,
            lockVersion: 1,
            doneRatio: null,
            estimatedHours: null,
            createdAt: '2024-04-29T08:32:59.368+00:00',
            updatedAt: '2024-04-29T08:32:59.477+00:00',
            startDate: '2024-05-19T22:00:00.000+00:00',
            responsibleId: null,
            budgetId: null,
            position: 1,
            storyPoints: null,
            remainingHours: null,
            derivedEstimatedHours: null,
            scheduleManually: false,
            parentId: 10,
            duration: 8,
            ignoreNonWorkingDays: false,
            derivedRemainingHours: null,
            derivedDoneRatio: null,
            children: [],
            relations: [],
        },
        {
            id: 12,
            typeId: 1,
            projectId: 1,
            subject: 'Party for conference supporters :-)',
            description: '*   [ ] Beer\n*   [ ] Snacks\n*   [ ] Music\n*   [ ] Even more beer',
            dueDate: '2024-05-29T22:00:00.000+00:00',
            categoryId: null,
            statusId: 1,
            assignedToId: 4,
            priorityId: 8,
            versionId: null,
            authorId: 4,
            lockVersion: 1,
            doneRatio: null,
            estimatedHours: null,
            createdAt: '2024-04-29T08:32:59.546+00:00',
            updatedAt: '2024-04-29T08:32:59.654+00:00',
            startDate: '2024-05-29T22:00:00.000+00:00',
            responsibleId: null,
            budgetId: null,
            position: 1,
            storyPoints: null,
            remainingHours: null,
            derivedEstimatedHours: null,
            scheduleManually: false,
            parentId: 10,
            duration: 1,
            ignoreNonWorkingDays: false,
            derivedRemainingHours: null,
            derivedDoneRatio: null,
            children: [],
            relations: [],
        },
    ],
    relations: [],
};
